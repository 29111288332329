type UploadImageResponse = {
  name: string
  link: string
}

export { type UploadImageResponse }

const images = {
  backgroundImage: require('../images/background-features.jpg'),
  background: require('../images/background.jpg'),
  hockey: require('../images/hockey.png'),
  screenshotExpenses: require('../images/screenshots/expenses.png'),
  screenshotPayroll: require('../images/screenshots/payroll.png'),
  screenshotReporting: require('../images/screenshots/reporting.png'),
  screenshotVatReturns: require('../images/screenshots/vat-returns.png'),
  backgroundImageFaq: require('../images/background-faqs.jpg'),
  backgroundImageCTA: require('../images/background-call-to-action.jpg'),
  logoLaravel: require('../images/logos/laravel.svg'),
  logoMirage: require('../images/logos/mirage.svg'),
  logoStatamic: require('../images/logos/statamic.svg'),
  logoStaticKit: require('../images/logos/statickit.svg'),
  logoTransistor: require('../images/logos/transistor.svg'),
  logoTuple: require('../images/logos/tuple.svg'),
  backgroundImageAuth: require('../images/background-auth.jpg'),
  screenshotContacts: require('../images/screenshots/contacts.png'),
  screenshotInventory: require('../images/screenshots/inventory.png'),
  screenshotProfitLoss: require('../images/screenshots/profit-loss.png'),
  avatarImage1: require('../images/avatars/avatar-1.png'),
  avatarImage2: require('../images/avatars/avatar-2.png'),
  avatarImage3: require('../images/avatars/avatar-3.png'),
  avatarImage4: require('../images/avatars/avatar-4.png'),
  avatarImage5: require('../images/avatars/avatar-5.png'),
  logo: require('../images/logo.svg'),
  mockup: require('../images/mockup.png'),
  mockup2: require('../images/mockup-2.png'),
  merch: require('../images/merch.png'),
  kiosk: require('../images/kiosk.png'),
  restaurang: require('../images/restaurang.png'),
  usp1: require('../images/usp-1.png'),
  usp2: require('../images/usp-2.png'),
  usp3: require('../images/usp-3.png'),
  usp4: require('../images/usp-4.png'),
  crowd: require('../images/crowd.png'),
  kiosk2: require('../images/kiosk_2.png'),
  ticket: require('../images/ticket.svg'),
  jersey: require('../images/jersey.svg'),
  burger: require('../images/burger.svg'),
  cutlery: require('../images/cutlery.svg'),
  phones: require('../images/phones.png'),
  hhcLogo: require('../images/hhc.webp'),
  hbkLogo: require('../images/hbk.png'),
  fchLogo: require('../images/fchLogo.png'),
  ovhLogo: require('../images/ovhLogo.png'),
  hifLogo: require('../images/hifLogo.png'),
  fffLogo: require('../images/fffLogo.png'),
  lfcLogo: require('../images/lfcLogo.png'),
  tivoliLogo: require('../images/tivoli.png'),
  emptyIphone: require('../images/iphone_empty.png'),
  locker: require('../images/locker.png'),
  background_crm: require('../images/background_crm.png'),
  bus: require('../images/bus.png'),
  iphone_mockup: require('../images/iphone_mockup.png'),
  hero_career: require('../images/hero_career.png'),
  pictures: require('../images/pictures.png'),
  light_bulb: require('../images/light-bulb.svg'),
  cart: require('../images/cart.svg'),
  thanks: require('../images/thanks.png'),
  concert: require('../images/concert.png'),
  iphone_hockey: require('../images/iphone_hockey.png'),
  iphone_kiosk: require('../images/iphone_kiosk.png'),
  iphone_restaurant: require('../images/iphone_restaurant.png'),
  background_about: require('../images/background_about.png'),
  hero_contact_me: require('../images/hero_contact_me.png'),
  quote: require('../images/quote.svg'),
  mockup_landslag: require('../images/mockup_landslag.webp'),
  mockup_crm: require('../images/mockup_crm.webp'),
  clipboard: require('../images/clipboard.svg'),
  talk: require('../images/talk.svg'),
  visual: require('../images/light-bulb.svg'),
  iphone_notification: require('../images/iphone_notification.png'),
  crm_mockup: require('../images/CRMmockup.webp'),
  liveday_logo: require('../images/liveday_logo.svg'),
  hero_platform: require('../images/hero_platform.png'),
  mockup_arena: require('../images/mockup_arena.webp'),
  mockup_customers: require('../images/mockup_customers.webp'),
  mockup_kiosk: require('../images/mockup_kiosk.webp'),
  mockup_products: require('../images/mockup_products.webp'),
  mockup_arena_select: require('../images/mockup_arena_select.png'),
  platform: require('../images/platform.svg'),
  ticket_dropdown: require('../images/ticket_dropdown.svg'),
  crm: require('../images/crm.svg'),
  marketing: require('../images/marketing.svg'),
  hero_marketing: require('../images/hero_marketing.png'),
  mockup_pre: require('../images/mockup_pre.png'),
  mockup_during: require('../images/mockup_during.png'),
  mockup_after: require('../images/mockup_after.png'),
  mockup_arena_phone: require('../images/mockup_arena_phone.webp'),
  locker_phone: require('../images/locker_phone.webp'),
  kiosk_phone: require('../images/kiosk_phone.webp'),
  checkout_phone: require('../images/checkout_phone.webp'),
  marketing1: require('../images/marketing1.webp'),
  marketing2: require('../images/marketing2.webp'),
  marketing3: require('../images/marketing3.webp'),
  hero_bg: require('../images/hero.jpg'),
  hero_mockup: require('../images/heromockup.png'),
  mockup_merch: require('../images/mockup_merch.webp'),
  quote2: require('../images/quote2.svg'),
  hero_job: require('../images/hero_job.png'),
  ticket2: require('../images/ticket2.svg'),
  mail_open: require('../images/mail_open.svg'),
  landing_middle: require('../images/landing_middle.png'),
  landing_crowd: require('../images/landing_crowd.jpg'),
  landing_phone: require('../images/landing_phone.jpg'),
  landing_first: require('../images/landing_first.jpg'),
}

export default images
